import React, { Component } from 'react'
import { LanguageHandler, Lang } from "service/LanguageHandler";
import { ChangeLanguageString } from "text/Home"

import "./scss/index.scss";

export class SwitchLanguage extends Component {
    state: { open: boolean, lang: Lang };
    constructor(props: any) {
        super(props);
        this.state = { open: true, lang: LanguageHandler.instance.value };
        window.addEventListener("scroll", (event) => {

            if (window.pageYOffset > 350 && this.state.open == true) {
                this.setState({ open: false });
            }
            if (window.pageYOffset < 200) {
                this.setState({ open: true });
            }
            console.log(window.pageYOffset)
        });


    }
    openClose() {
        this.setState({
            open: !this.state.open
        })
    }

    setLanguage(lang: Lang) {

        this.setState({
            lang: lang
        });
        LanguageHandler.instance.changeLang(lang);
    }

    render() {
        return (
            <div className={`
            switch-language 
            ${this.state.open === true ? "" : "fixed"}
            `}>
                <button onClick={
                    () => {

                        this.openClose();
                    }
                } className="text">
                    <ChangeLanguageString />
                </button>
                <div onClick={
                    () => {
                        if (this.state.lang === "it") {
                            this.setLanguage(Lang.EN);
                        }
                        if (this.state.lang === "en") {
                            this.setLanguage(Lang.IT)
                        }
                    }
                } className="switch">
                    <div className={
                        `
                        circle
                        ${this.state.lang === "en" ? "right" : "left"}
                        `
                    }></div>
                    <div className={`
                    lang it ${
                        this.state.lang === "it" ? "active" : ""
                        }
                    `}></div>
                    <div className={`
                    lang en ${
                        this.state.lang === "en" ? "active" : ""
                        }
                    `}></div>
                </div>
            </div>
        )
    }
}
