import React from "react";
import "./scss/row.scss";
import { Flex } from "./Flex";
import { FlexProps } from "./base";

export interface RowProps extends FlexProps {
    children?: any;
}

export class Row extends Flex {

    constructor(public props: RowProps) {
        super(props);
    }
    render() {
        return (
            <div style={this.style} className="spinanico-row">
                {this.props.children}
            </div>
        )
    }
}
