

import React, { Component } from 'react'
import Coding from './_Coding'
import Course from './_Course'
import IAM from './_Iam'
import "./assets/index.scss";

export default class index extends Component {
    render() {
        return (
            <div className="presentation-container">


                <div className="texts">
                    <div className="paragraphs">
                        <IAM className="columns" />
                        <Coding className="columns reverse" />
                        <Course className="columns" />
                        {/* <Space className="columns reverse" /> */}
                    </div>
                </div>

            </div>
        )
    }
}
