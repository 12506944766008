import React, { Component } from 'react'

export interface ExpandedProps {
    children?: any;
    flex?: number;
}

export class Expanded extends Component {

    constructor(public props: ExpandedProps) { super({}); }
    render() {
        return (
            <div
                style={{
                    flexGrow: this.props.flex || 1
                }}
                className="spinanico-expanded">
                {this.props.children}
            </div>
        )
    }
}
