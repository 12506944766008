import React, { Component } from 'react'
import "./scss/center.scss";

const _Center = (p: any) => React.createElement("center", {
    children: p.children
})

export class Center extends Component {
    render() {
        return (
            <_Center>
                {this.props.children}
            </_Center>
        )
    }
}
