import React, { Component } from 'react'
import "./scss/white-box.scss";

export interface BoxProps {
    className?: string;
    children?: any;
}

export class Box extends Component {

    constructor(public props: BoxProps) { super({}); }

    render() {
        return (
            <div className={`${this.props.className || ""} white-box`}>
                {this.props.children}
            </div>
        )
    }
}
