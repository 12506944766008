import React from 'react'
import { Flex } from "./Flex";
import { FlexProps } from "./base"
import "./scss/columns.scss";


export class Column extends Flex {

    constructor(public props: FlexProps) {
        super(props);
    }

    render() {
        return (
            <div
                style={this.style}
                className="spinanico-columns">
                {this.props.children}
            </div>
        )
    }
}
