import React, { Component } from 'react'
import { SocialLink } from "LandingPage/_Const"
import "./style.scss";

export default class index extends Component {
  render() {
    return (
      <div className="container-social">
        <div className="social-zone">
          <a href={SocialLink.youtube} target="_blanck"><div className="social youtube"></div></a>
          <a href={SocialLink.github} target="_blanck"><div className="social github"></div></a>
          <a href={SocialLink.instagram} target="_blanck"><div className="social instagram"></div></a>
        </div>
      </div>
    )
  }
}
