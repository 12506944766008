import React, { Component, ButtonHTMLAttributes } from 'react'
import "./scss/normal_button.scss";

export interface ClassicButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
    buttonColor?: ButtonColors;
}
export enum ButtonColors {
    Red, Blue, Purple, White, Grey, Black
}

export class Button extends Component {

    constructor(public props: ClassicButtonInterface) { super({}); }

    get color(): string {
        switch (this.props.buttonColor) {
            case ButtonColors.Red:
                return "red-button";
            case ButtonColors.Black:
                return "black-button";
            default:
                return "white-button";
        }
    }

    render() {
        return (
            <button
                {...this.props}
                className={`button spinanico-button ${this.color}`}
            >{this.props.children}</button>
        )
    }
}
