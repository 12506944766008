import React, { Component } from 'react'
import { Center } from "../../Composer/layouts/center";
import "./footer.scss";
export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-box">
                    <Center>
                        <b>05692280877</b>
                    </Center>
                </div>
                <br /><br />
                <div className="footer-box">
                    <Center>
                        <b>created with React and Love!</b>
                    </Center>
                    <div className="icon react-love"></div>
                </div>
            </div>
        )
    }
}
