import React from "react";

export const Title = (props: any) => {
    return (
        <h3 style={{
            color: props.color || "#666"
        }}>{props.children}</h3>
    )
}

export const Paragraph = (props: any) => {
    return (
        <p style={{ fontSize: "17px" }}>
            {props.children}
        </p>

    );
}
