import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { Footer } from "./reuse/Footer";
import Books from "./Books";
import Main from "./LandingPage";
import { SwitchLanguage } from "reuse/SwitchLanguage";
import CV from "CV";

export class Application extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page">
                    <SwitchLanguage />
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Main} />
                            {/* <Route path="/courses/:topic?" component={Courses} />
                            <Route path="/course/:id" component={Course} /> */}
                            <Route path="/books" component={Books} />
                            <Route path="/CV" component={CV} />
                            {/* <Route path="/projects" component={Projects} />
                            <Route path="/projects/:id" component={NoComponent} /> */}
                            {/* <Route path="/debug" component={App} /> */}
                        </Switch>
                    </BrowserRouter>
                    <Footer />
                </div>

            </React.Fragment>
        )
    }
}
