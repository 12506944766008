import React from "react";
import { LangComponent } from "./_LanguageComponent"
import { Title, Paragraph } from "./_utils";
import { Center } from "Composer/layouts";

export class ChangeLanguageString extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>Change language</React.Fragment>
    }
    it(): JSX.Element {
        return <React.Fragment>Cambia lingua</React.Fragment>
    }

}


export class HiMyNameIsNico extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>

            <Title>Hey Hello! I introduce myself.</Title>
            <Paragraph>
                I am Nico, a young Sicilian developer. Curious. Outgoing. Ambitious.
                In my new site I decided to tell you a little about myself but, above all,
            I want to continue to grow more and more because there is always so much to learn.<br /><br />

                It is important for my personal growth to make me notice my oversights and my mistakes.
            So I count on you:<b>feel free to contact me to improve me!</b>
            </Paragraph>

        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>
            <Title>Ehi, ciao! Mi presento.</Title>
            <Paragraph>
                Sono Nico, un giovane developer siciliano. Curioso. Estroverso. Ambizioso.

                In questo mio nuovo sito ho deciso di raccontarvi un po’ di me ma, soprattutto,
                voglio continuare a crescere sempre di più perché c’è sempre tanto da imparare.<br /> <br />
                È importante, per la mia crescita personale, farmi notare le mie sviste e i miei errori.
                Quindi conto su di te: <b>sentiti libero di contattarmi per migliorarmi!</b>

            </Paragraph>
        </React.Fragment>
    }


}

export class CodeMyPassion extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title>The Code, my passion!</Title>
            <Paragraph>
                I have been programming for several years 13 years I am fascinated by computer
                science and technology since always.<br /><br />

                My knowledge is based on Web technologies, both in
                the back-end but also in the front-end.
                I also have a good knowledge for Mobile / desktop development.
                <br /><br />
                Over the years I have become increasingly familiar with open source.
                For the past 5 years I have been using Linux distributions as the main operating system.
            </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>
            <Title>Il Codice, la mia passione!</Title>
            <Paragraph>
                Programmo da svariati anni {(new Date()).getFullYear() - 2006} anni
                sono affascinato dall'informatica e dalla tecnologia
                        da sempre.<br /><br />
                Il mio sapere è orientato su tecnologie Web, sia in ambito back-end
                        ma anche front-end.  <br />
                Ho anche una discreta conoscenza per lo sviluppo Mobile/desktop. <br /><br />
                Negli anni mi sono sempre più avvicinato all'open source.
                        Da ormai {(new Date()).getFullYear() - 2014} anni
uso distibuzioni Linux come principale sistema operativo.
                    </Paragraph>

        </React.Fragment>
    }


}


export class VideoText extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>

            <Title> Video</Title>
            <Paragraph>
                I have always had the pleasure of explaining to others, it has always been my vocation,
                the pleasure of being able to make myself understood even by those who are not in the sector,
is something that fills me with pride.<br /><br />

                I admit that initially it was difficult to explain computer science,
                but over the years with a lot of patience, I have refined my art in explaining
even the most complex topics in this sector.<br /><br />

                This desire to explain to my neighbor was not lost over time,
                <b>I recently decided to open a YouTube channel.</b>
            </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>
            <Title>Video</Title>
            <Paragraph>
                Da sempre ho avuto il piacere di spiegare al prossimo,
                è sempre stata una mia vocazione, il piacere di riuscire
                a farmi capire anche da chi non è del settore,
            è qualcosa che mi riempie di orgoglio. <br /><br />
                Ammetto che inizialmente era difficile spiegare l'informatica,
                ma negli anni con molta pazienza, ho affinato molto l'arte
            nello spiegare anche gli argomenti più complessi di questo settore. <br /><br />

                Questa voglia di spiegare al mio prossimo non è andata persa nel tempo, <b>da
            poco ho deciso di aprire un canale youtube. <br /></b>

            </Paragraph>
        </React.Fragment>
    }


}


export class HowIStarted extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title>How did I start?</Title>
            <Paragraph>
                A bit like so many in this area, my passion comes when I was just a kid.
                At 13, my first "Hello world!" to 14 my first internet searches.<br /><br />

                From there I never stopped! today there is no day that I do not plan,
                the beauty of my work is that it coincides with my passion.
                I would have planned anyway even if it wasn't my job.
                </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>

            <Title>Come ho iniziato?</Title>
            <Paragraph>
                Un po' come tanti in questo settore,
                la mia passione nasce quando ero appena un ragazzino.
                A 13 anni il mio primo "Hello world!" a 14 le mie prime
                ricerche su internet.
            <br /><br />
                Da lì non mi sono più fermato! ad oggi non c'é giorno che non programmo,
                il bello del mio lavoro è che coincide con la mia passione.
            <br />
                Avrei comunque programmato anche se non fosse il mio lavoro.
        </Paragraph>
        </React.Fragment>
    }


}

export class WhyIDoit extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title> Why do I do it?</Title>
            <Paragraph>
                Two great motivations lead me to learn and cultivate this passion,
                the first is the satisfaction and elitating feeling of seeing well-done code.
                    <br /><br />

                The second: the thirst for knowledge.
                I never backed out to learn new technologies, I read a mountain of documentation combined with books, which regards software development and software architecture, every day.
            </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>

            <Title>Perché lo faccio?</Title>
            <Paragraph>
                Due grandi motivazioni mi spingono ad imparare e coltivare questa passione,
                la prima è la soddisfazione ed eletrizzante
                sensazione di vedere del codice ben fatto.
            <br /><br />
                La seconda: <b>la sete di sapere.</b><br />
                Non mi sono mai tirato indietro a imparere nuove tecnologie,
                leggo quotidianamento una montagna di documentazione unita a libri,
            che riguarda lo sviluppo software e architettura software. <br />
                <br />

            </Paragraph>

        </React.Fragment>
    }


}


export class NotOnlyCoding extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title>Not just code!</Title>
            <Paragraph>
                I also have other passions besides the code, from the kitchen to astrophysics.
                </Paragraph>

        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>
            <Title>Non solo codice!</Title>
            <Paragraph>
                Ho anche altre pasioni oltre al codice,
                                    dalla cucina, all'astrofisica.<br />
                <br />
            </Paragraph>
        </React.Fragment>
    }


}

export class BookEntryText extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title color="#fff">Books</Title>
            <Paragraph>
                One of my passions that unites them all is definitely reading.
                In this section I wanted to collect only some of the books I have read, and that I warmly recommend.
            Among these books there are related and completely separate topics.<br /><br />

                I invite you to recommend new readings! and I leave you with my personal definition of knowledge:
            <br /><br />
                <Center>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <i>
                            "Knowledge is an eternal goal,<br />
                            the closer you get to it, the more it will be elusive and distant. "
                        </i>
                    </div>
                </Center>
                <hr />
            </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>

            <Title color="#fff">Libri</Title>
            <Paragraph>

                Una della mie passioni che un po' le unisce tutte è sicuramente la lettura.
                                            <br />
                In questa sezione ho voluto raccogliere solo alcuni dei libri che ho letto,
                                            e che con piacere vi consiglio caldamente. <br />
                Tra questi libri ci sono argomenti affini e completamente separati.
                                            <br />
                <br />
                Vi invito a consigliarmi delle nuove letture! e vi lascio con una mia
                                            personale definizione del sapere:<br /><br />
                <Center>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <i>"Il sapere è un eterno traguardo,<br />
                            più ti avvicini ad esso, più esso sarà inafferrabile e distante."</i>
                    </div>
                </Center>
                <hr />
            </Paragraph>
        </React.Fragment>
    }


}


export class BookButtonText extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>in my library</React.Fragment>;
    }
    it(): JSX.Element {
        return <React.Fragment>nella mia libreria</React.Fragment>
    }


}

export class ContactMeOffline extends LangComponent {
    en(): JSX.Element {
        return <React.Fragment>
            <Title>Contact me!</Title>
            <Paragraph>
                The contact form is suspect, due to some updates to the site. =)<br />
                You can contact me directly at my personal email:
                    <br /><br />
                <b>spinanico93@gmail.com</b>
            </Paragraph>
        </React.Fragment>
    } it(): JSX.Element {
        return <React.Fragment>

            <Title>Contattami!</Title>
            <Paragraph>
                Il form di contatto è in sospeto, per via di alcuni aggiornamenti al sito.
                =)
            <br />
                Puoi contattarmi direttamente alla mia email personale:
                        <br /><br />
                <b>spinanico93@gmail.com</b>
            </Paragraph>
        </React.Fragment>
    }


}