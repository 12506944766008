import React, { Component } from 'react'
import { Box } from 'reuse/Box'
import "./scss/contactme.scss";
import { Center } from 'Composer/layouts';
import { TextField } from "Composer/inputs";
import { TextArea } from 'Composer/inputs/TextArea';
import { ContactMeOffline } from 'text/Home';

export class ContactMe extends Component {
    render() {
        return (
            <Center>
                <Box className="contactme">
                    <Offline />
                    {/* <TextField /><br />
                    <TextField /><br />
                    <br />
                    <TextArea /> */}
                </Box>
            </Center>
        )
    }
}


const Offline = (_: any) => {
    return <React.Fragment>
        <ContactMeOffline />
    </React.Fragment>
}