import React, { Component } from 'react'
import "./scss/photo.scss";
import { Center } from 'Composer/layouts';

export default class Photo extends Component {
    render() {
        return (
            <Center>

                <div className="photo-cv">

                </div>
            </Center>
        )
    }
}
