import React, { Component } from 'react'
import { Button, ButtonColors } from "Composer/button";
import { Box } from 'reuse/Box';
import { SocialLink } from 'LandingPage/_Const';
import { VideoText } from 'text/Home';

export default class Video extends Component {

    props: { className: string }
    constructor(props: { className: string }) {
        super({});
        this.props = props;
    }
    render() {
        return (
            <Box className={this.props.className}>
                <div className="icon course">
                </div>
                <div>
                    <VideoText />

                    <div style={{ textAlign: "center" }}>
                        <a href={SocialLink.youtube} target="_blank">
                            <Button buttonColor={ButtonColors.Red}>youtube!</Button>
                        </a>
                    </div>
                </div>
            </Box>

        )
    }
}
