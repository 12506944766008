import React, { Component } from 'react'
import { Box } from 'reuse/Box'
import { Row, mainAxisSize, crossAxisAlignment, mainAxisAlignment } from 'Composer'
import "./scss/books.scss";
import { Book } from 'service/BooksHandler';

interface BookProps {
    book: Book
}

export class BookComponent extends Component {
    render() {
        return (
            <div style={{
                maxWidth: "800px",
                width: "100%"
            }}>
                <Box className="block">
                    <Row mainAxisSize={mainAxisSize.max}
                        mainAxisAlignment={mainAxisAlignment.spaceAround}
                        crossAxisAlignment={crossAxisAlignment.center}>
                        <div className="cover-book">
                            ciao
                        </div>
                        <div className="book-info">
                            lol
                        </div>

                    </Row>
                </Box>
            </div>
        )
    }
}
