import React, { Component } from 'react'
import { Row } from 'Composer/layouts/row'
import { Column, mainAxisAlignment, Expanded, crossAxisAlignment, Center } from 'Composer/layouts'
import { Box } from 'reuse/Box'

import "./scss/skills.scss";
import { HowIStarted, WhyIDoit } from 'text/Home';

export default class SkillsComponent extends Component {
    render() {
        return (
            <Center>
                <div className="container-of-skill">
                    <Row
                        crossAxisAlignment={crossAxisAlignment.strech}
                        mainAxisAlignment={mainAxisAlignment.spaceEvenly}>
                        <Expanded flex={1}>
                            <Column crossAxisAlignment={crossAxisAlignment.strech}>
                                <Expanded flex={1}>
                                    <Box>
                                        <HowIStarted />
                                    </Box>
                                </Expanded>
                                <Expanded flex={1}>
                                    <Box>
                                        <WhyIDoit />
                                    </Box>
                                </Expanded>
                            </Column>
                        </Expanded>
                        <Expanded>
                            <Column
                                crossAxisAlignment={crossAxisAlignment.strech}
                                mainAxisAlignment={mainAxisAlignment.spaceAround}>

                                <div className="skill flutter"></div>
                                <div className="skill go"></div>
                                <div className="skill python"></div>
                                <div className="skill javascript"></div>

                            </Column>
                        </Expanded>
                    </Row>
                </div>
            </Center>
        )
    }
}
