import React, { Component } from "react"
import { LanguageHandler, Lang } from "service/LanguageHandler"
import "./scss/lang-container-effect.scss";

export abstract class LangComponent extends Component {
    state: { lang: Lang, blured: boolean }
    constructor(props: any) {
        super({})
        this.state = {
            lang: Lang.IT,
            blured: false
        }
        LanguageHandler.instance.listenLang((value) => {
            this.setState({
                blured: true
            })

            setTimeout(() => {
                this.setState({
                    lang: value,
                    blured: false
                })
            }, 300)
        })

    }

    render() {
        return <div className={`lang-container ${this.state.blured === true ? "blured" : ""} `}>
            {this.state.lang === Lang.EN ? this.en() : this.it()}
        </div>
    }

    abstract en(): JSX.Element;
    abstract it(): JSX.Element;


}