import React, { Component } from 'react'
import "./style.scss";
interface LoadingProps {
    size: number;
    hidden: boolean;
    message?: string;
}

interface LoadingState {
    hide: boolean
}

export class Loading extends Component {
    props: LoadingProps
    state: LoadingState

    setState(state: LoadingState) {
        super.setState(state);
    }
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            hide: props.hidden,
        }
        this.props = props;
    }

    render() {
        return (
            <div
                style={{
                    width: this.props.size + "px",
                    height: this.props.size + "px"
                }}
                className={
                    `
                    ${this.state.hide === true ? "hide" : "show"}
                    loading-box`
                }>
                <div className={`message`}>{this.props.message || ""}</div>
            </div>
        )
    }
}
