import { Component, CSSProperties } from 'react'
import { mainAxisAlignment, mainAxisSize, crossAxisAlignment, FlexProps } from "./base";

export class Flex extends Component {

    constructor(public props: FlexProps) { super({}) }

    get mainAxisAligment(): string {
        // flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
        switch (this.props.mainAxisAlignment) {
            case mainAxisAlignment.spaceAround:
                return "space-around";
                break;
            case mainAxisAlignment.center:
                return "center";
                break;
            case mainAxisAlignment.spaceEvenly:
                return "space-evenly";
                break;
            case mainAxisAlignment.spaceBetween:
                return "space-between";
                break;
            case mainAxisAlignment.spaceEvenly:
                return "space-evenly";
                break;
            case mainAxisAlignment.end:
                return "flex-end";
                break;
            case mainAxisAlignment.start:
                return "flex-start";
                break;
        }
        return "start";
    }

    get crossAxisAlignment(): string {
        switch (this.props.crossAxisAlignment) {
            case crossAxisAlignment.strech:
                return "strech";
                break;
            case crossAxisAlignment.baseline:
                return "baseline";
                break;
            case crossAxisAlignment.start:
                return "start"
                break;
            case crossAxisAlignment.end:
                return "end"
                break;
        }
        return "center";
    }

    get style(): CSSProperties {
        return {
            justifyContent: this.mainAxisAligment,
            alignItems: this.crossAxisAlignment

        } as CSSProperties;
    }
}
