import React, { Component } from 'react'
import { HiMyNameIsNico } from "text/Home"
import { Box } from 'reuse/Box';

export default class IAM extends Component {
    props: { className: string }
    constructor(props: { className: string }) {
        super({});
        this.props = props;
    }

    render() {
        return (
            <Box className={this.props.className}>
                <div className="icon iam"></div>
                <div>
                    <HiMyNameIsNico />
                </div>

            </Box>
        )
    }
}
