import { BehaviorSubject } from "rxjs"


export enum Lang { IT = "it", EN = "en" }

export class LanguageHandler {
    private lang: BehaviorSubject<Lang>;
    private static _instance: LanguageHandler;

    private constructor() {
        this.lang = new BehaviorSubject<Lang>(Lang.EN);
    }

    static get instance(): LanguageHandler {
        if (!LanguageHandler._instance) {
            LanguageHandler._instance = new LanguageHandler();
        }
        return LanguageHandler._instance;
    }

    listenLang(f: (lang: Lang) => void) {
        this.lang.subscribe(f)
    }
    get value(): Lang {
        return this.lang.value;
    }
    changeLang(lang: Lang) {
        this.lang.next(lang);
    }

}