import React, { Component } from 'react'
import { Container } from 'reuse/Container'
import { BookComponent } from "./components/book";
import { Center, Column } from 'Composer/layouts'
import { Box } from 'reuse/Box'
import { Loading } from "reuse/Loading"

export default class index extends Component {
    render() {
        return (
            <div>
                <Column>

                    <br />
                    <h1>Books</h1>
                    <Box >
                        <Center>
                            In Questa sesione provo ad elencare i libri che più mi hanno colpito.
                            <br />
                            ti ricordo che puoi consigliarmi un libro! contattandomi.

                    </Center>
                    </Box>
                </Column>
                <hr />

                <hr />

                <Column>
                    <Loading hidden={false} size={100} />
                    <BookComponent />
                </Column>
            </div>
        )
    }
}
