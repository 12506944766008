import React, { Component } from 'react'
import { Box } from 'reuse/Box';
import Photo from "./components/Photo";
import "./scss/style.scss";
import { Center, Column, Row, mainAxisAlignment, Expanded } from 'Composer/layouts';
import { Container } from 'reuse/Container';
import { Button } from 'Composer';



interface SkillProps {
    name: string
    children: any;
}
class Skill extends Component {
    props: SkillProps;
    constructor(props: SkillProps) {
        super(props);
        this.props = props;
    }

    render() {
        return <div className="skill-cv">

            <div>
                <Column mainAxisAlignment={mainAxisAlignment.center}>

                    <div className={`icon ${this.props.name}`}></div>
                </Column>
            </div>

            <div className="text-skill">

                {this.props.children}
            </div>


        </div >
    }

}

interface SocialLinkProps {
    name: string;
    link: string;
    text: string;
}

class SocialLink extends Component {
    props: SocialLinkProps
    constructor(props: SocialLinkProps) {
        super(props)
        this.props = props;
    }
    render() {
        return <div className={`socialinfo ${this.props.name}`}>
            <Row mainAxisAlignment={mainAxisAlignment.spaceAround}>

                <div className={`icon ${this.props.name}`}></div>
                <a target="blank" href={this.props.link} className="link"> {this.props.text}</a>
            </Row>
        </div>
    }
}

export default class index extends Component {

    componentDidMount() {
        let nodes: ArrayLike<Element | null> = [
            window.document.querySelector(".switch-language"),
            window.document.querySelector(".footer"),

        ]
        for (let i = 0; i < nodes.length; i++) {
            let c = nodes[i];
            if (c !== null)
                c.remove();
        }
    }

    render() {
        return (
            <Center>
                <div className="cv-page">
                    <Photo />
                    <Box>
                        <h5>Presentazione/Contatti</h5>
                        <p>
                            Mi chiamo <b>Spina Nico</b> sono un <b>libero professionista</b> che si occupa di sviluppo software, su piattaforme
                            web e dispositivi mobile.<br />
                            <br />
                            <b>Nato a Catania nel 07/10/1993, e residente a Santa Venerina(CT), 95010</b><br />
                            Diplomato nel 2012 presso <b>ITIS E.Fermi di Giarre</b>
                            <br /><br />


                            Per ulteriori informazioni e ricontatto.<br />

                            <div className="socials">

                                <SocialLink link="https://www.linkedin.com/in/spinanico/" text="linkedin.com/in/spinanico/" name="in" />
                                <SocialLink link="https://github.com/SpinaNico" text="github.com/SpinaNico" name="git" />
                                <SocialLink link="mailto:spinanico93@gmail.com" text="spinanico93@gmail.com" name="email" />

                            </div>
                            <hr />
                            <i>
                                Questo <a href="https://spinanico.com/CV">Curriculum</a> è disponibile online sul mio sito <a href="https://spinanico.com">
                                    spinanico.com</a>.

                                </i>
                        </p>
                    </Box>

                    <Box>
                        <h3>Ultima/Attuale esperienza lavorativa</h3>
                        <p>
                            La realizzazione dell'applicazione "eNigma", disponibile nei principali store,
                            è stata interamente diretta da me, ho realizzato il frontend affiancato dalle direttive
                            del grafico, e ho realizzato tutto il backend, testandolo e mettendo in produzione in soluzioni scalabili.<br />
                            <br />

                            <a target="blank" href="https://nigma.es/go"><Button> Vedi applicazione</Button></a>
                        </p>

                        <i>Immagini relative all'applicazione</i>
                        <div className="gallery">
                            <div className="photo p1"></div>
                            <div className="photo p2"></div>
                        </div>
                    </Box>


                    <Box>
                        <h3>Competenze</h3>
                        Sviluppo da 13 anni, formando svariate competenze negli anni, coltivo le mie competenze con molta cura,
                        aggiornandomi e tenendo sempre il passo con la tecnologia.<br />
                        <br />


                        <hr />
                        <Skill name="flutter">
                            <h4>Flutter</h4>
                            <b>Da un anno e mezzo</b> sviluppo attivamente ogni giorno con flutter il nuovo SDK,
                            prodotto da google.<br /><br />
                            Flutter è stato ampiamente usato, nella mia ultima esperienza lavorativa, dove ho curato
                            la creazione di tutto il <b>front-end per IOS / Android.</b>
                        </Skill>

                        <Skill name="go">
                            <h4>Golang</h4>
                            <b>Da due anni</b> mi sono avvicinato a golang, in maniera immersiva e completa, uso questo linguaggio
                            che è diventato in poco tempo tra i miei preferiti, attivamente e in quasi la totalità dei progetti.
                            <br /><br />
                            Nella mia ultima esperienza lavorativa è stato ampiamente usato per la realizzazione di un <b>backend scalabili
                            orizzontalmente.</b>
                        </Skill>


                        <Skill name="python">
                            <h4>Python</h4>
                            <b>Da 6 anni</b> sviluppo attivamente con python, utilizzo questo linguaggio per mettere velocemente in produzione,
                            è stato ampiamente usato nel backend e in molte automazioni nella mia ultima esperienza lavorativa.
                        </Skill>

                        <Skill name="javascript">
                            <h4>Javascript</h4>
                            <b>Da 6 anni</b> uno dei mei linguaggi più consolidati, utilizzo javascript da sempre,
                            orientato più per lo sviluppo web
                            con librerie come <b>React.</b>
                        </Skill>
                    </Box>

                    <Box>
                        <h4>React</h4>
                        Uno delle librerie Javascript più consolidate è react, da 3 anni la uso intensamente
                        con cadenza settimanale,
                        con l'ausilio di <b>Typescript</b>. Sono solito
                        abinare con  <b>Redux e/o rxJs</b> per gestire lo stato a livello applicativo.
                    </Box>

                    <Box>
                        <Column mainAxisAlignment={mainAxisAlignment.center}>
                            <br />
                            <b>
                                Autorizzo il trattamento dei miei dati personali presenti nel curriculum ai sensi del Decreto Legislativo 30 giugno 2003, n. 196 e del GDPR (Regolamento UE 2016/679).
                            </b>
                        </Column>
                    </Box>
                    <br />
                </div>
            </Center>
        )
    }
}
