
export enum mainAxisAlignment {
    spaceEvenly,
    spaceBetween,
    spaceAround,
    center,
    start,
    end,
}

export enum crossAxisAlignment {
    strech,
    baseline,
    center,
    end,
    start
}

export enum mainAxisSize {
    max, min
}
export interface FlexProps {
    crossAxisAlignment?: crossAxisAlignment;
    mainAxisAlignment?: mainAxisAlignment;
    mainAxisSize?: mainAxisSize;
    children?: any;
}