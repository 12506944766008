import React, { Component } from 'react'
import { Button, ButtonColors } from 'Composer/button';
import { Row } from "Composer/layouts/row";
import { mainAxisAlignment } from 'Composer/layouts/base';
import { Box } from "reuse/Box";
import { SocialLink } from 'LandingPage/_Const';
import { CodeMyPassion } from 'text/Home';

export default class Coding extends Component {

    props: { className: string }
    constructor(props: { className: string }) {
        super({});
        this.props = props;
    }
    render() {
        return (
            <Box className={this.props.className}>
                <div>
                    <CodeMyPassion />
                    <Row mainAxisAlignment={mainAxisAlignment.spaceAround}>
                        <a target="_blank" href={SocialLink.github}>
                            <Button buttonColor={ButtonColors.Black} >Github</Button>
                        </a>
                    </Row>
                </div>
                <div className="icon coding"></div>
            </Box>

        )
    }
}
