import React, { Component } from 'react'
import { Center, Column, crossAxisAlignment, Expanded, Row, mainAxisAlignment } from 'Composer/layouts'
import "./scss/bookEntry.scss";
import { Button } from 'Composer/button';
import { Container } from "reuse/Container";
import { BookEntryText, NotOnlyCoding, BookButtonText } from 'text/Home';
import { Lang, LanguageHandler } from 'service/LanguageHandler';
import { Link } from 'react-router-dom';

export default class BookEntry extends Component {


    alertNotWork() {
        if (LanguageHandler.instance.value === Lang.IT) {
            alert("La sezione è in ricostruzione :) torna a trovarmi tra qualche giorno!")
        } else {
            alert("this section it's not complete! ")
        }
    }

    gotoBooksPage() { }

    render() {
        return (
            <div className="book-entry-container">
                <Column crossAxisAlignment={crossAxisAlignment.strech}>
                    <Center>
                        <Container>
                            <div className="book-text">
                                <NotOnlyCoding />
                            </div>
                        </Container>
                    </Center>

                    <Center>
                        <div className="book-box">
                            <Column
                                mainAxisAlignment={mainAxisAlignment.spaceAround}
                                crossAxisAlignment={crossAxisAlignment.strech}>

                                <Expanded flex={3}>
                                    <div style={{ textAlign: "left" }}>
                                        <BookEntryText />
                                    </div>
                                </Expanded>
                                <Expanded flex={1}>
                                    <Row
                                        crossAxisAlignment={crossAxisAlignment.strech}
                                        mainAxisAlignment={mainAxisAlignment.end} >
                                        {/* <Link to="/books"> */}
                                        <Button onClick={
                                            () => {
                                                alert("this section it's not complete.")
                                            }
                                        }>
                                            <BookButtonText />
                                        </Button>
                                        {/* </Link> */}
                                    </Row>
                                </Expanded>
                            </Column>
                        </div>
                    </Center>
                </Column>
            </div>
        )
    }
}
