import React, { Component } from 'react'
import "./scss/headers.scss";
import Social from "LandingPage/Head/Social";
import { Button, Row } from 'Composer';

export default class index extends Component {

  random(): number {
    return (Math.random() * 40) + 20;
  }

  render() {
    return (
      <div className="window-header" >

        <Social />

        <div className="my-fuck-face">

          <div className="my-fuck-name">Spina Nico</div>

        </div>

      </div>
    )
  }
}
