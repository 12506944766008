import React, { Component } from 'react'
import "./scss/_ground.scss"
export default class _myGround extends Component {
    render() {
        return (
            <div className="ground-spinanico">
                <div className="svg svg1"></div>
                <div className="svg svg2"></div>
                <div className="svg svg3"></div>
                <div className="svg svg4"></div>
                <div className="svg svg5"></div>
                <div className="svg svg6"></div>
            </div>
        )
    }
}
