import React, { Component } from 'react'
import Header from './Head';
import Presentation from "./Presentation";
import Skills from "./SkillsComponent";
import BookEntry from "./BookEntry";
import Ground from "./_myGround";
import { ContactMe } from './ContactMe';

class index extends Component {
  render() {
    return (
      <div>
        <Ground />
        <div style={{ zIndex: 2, position: "relative" }}>
          <Header />
          <Presentation />
          <Skills />
          <BookEntry />
          <ContactMe />
        </div>

      </div>
    )
  }
}

export default index